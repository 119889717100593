import React, {useEffect} from 'react';
import '../static/investorRelations.css';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import {HeaderWhiteTransparent} from '../components/Header';

import HeroImageOne from '../assets/img/investor-relations/hero-section-image-one.png';
import HeroImageTwo from '../assets/img/investor-relations/hero-section-image-two.png';
import HeroImageThree from '../assets/img/investor-relations/hero-section-image-three.png';
import HeroImageFour from '../assets/img/investor-relations/hero-section-image-four.png';
import AboutProductImage from '../assets/img/investor-relations/about-product-section-image.png';
import MarketPossibilitiesImage from '../assets/img/investor-relations/market-possibilities-image.png';

import { ReactComponent as MarketPossibilitiesObject } from '../assets/market-possibilities.svg'

import { ReactComponent as ArrowRightButton } from '../assets/arrow-right.svg'

const InvestorRelationsPage = () => {
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        gsap.set(".our-product-image", {y: 50})
        gsap.to(".our-product-image", {
            boxShadow: "53px -50px 12px 0px rgba(0, 0, 0, 0.25)",
            y: -50,
            scrollTrigger: {
                trigger: "#our-product-section",
                start: "top 75%",
                end: "bottom 10%",
                scrub: true
            }
        })
    }, [])

  return (
    <div className='investor-relations-page-bg'>
        <HeaderWhiteTransparent />

        <section className='container-fluid d-flex flex-column justify-content-between justify-content-sm-start align-items-end position-relative m-0 p-0' style={{height: "100vh"}}>
            <div className='col-sm-11 d-flex flex-row justify-content-end justify-content-sm-between move-from-header'>
                <div className='hero-image' style={{width: "23%"}}>
                    <img src={HeroImageOne} className='hero-images' />
                </div>

                <div className='hero-image' style={{width: "23%"}}>
                    <img src={HeroImageTwo} className='hero-images' />
                </div>

                <div className='hero-image' style={{width: "23%"}}>
                    <img src={HeroImageThree} className='hero-images' />
                </div>

                <div className='hero-image' style={{width: "18%"}}>
                    <img src={HeroImageFour} className='hero-images' />
                </div>
            </div>
            
            <div className='col-11 d-flex flex-column flex-lg-row justify-content-between row-gap-5 mt-4 mb-5 pb-5 pt-1'>
                <div className='col-11 col-lg-9'>
                    <p className='text-black fs-4 font-inter-light'>Servin strives to be the leading platform that makes it easy to find and hire trusted professionals for domestic and professional tasks. We believe in convenience, transparency and the highest quality of service for every customer.</p>
                </div>

                <div className='col-12 col-md-4 col-lg-3 d-flex justify-content-start justify-content-lg-center align-items-start'>
                    <a href='/contacts/' className='text-dark font-anton fs-3 bg-white rounded-3 px-3 py-2 d-flex align-items-center'>Contact Us <ArrowRightButton className="ms-2" /></a>
                </div>
            </div>
        </section>

        <section className='d-flex flex-column flex-md-row justify-content-end align-items-center row-gap-5 py-5 my-5' id='our-product-section' style={{minHeight: "75vh"}}>
            <div className='col-11 col-sm-10 col-md-7 d-flex flex-column'>
                <div className='col-11 col-sm-10 col-lg-9'>
                    <h1 className='text-black title-text font-anton'>Our Product</h1>
                    <p className='text-black responsive-text-2'>Servin is an online platform that connects customers with trusted experts in various fields, from small household repairs to professional advice. The platform offers a user-friendly interface for ordering services, a rating system, and flexible payment terms.</p>
                </div>
            </div>
            <div className='col-6 col-md-4 align-self-end'>
                <div className='col-6 col-md-8'>
                    <img src={AboutProductImage} className='img-fluid our-product-image' />
                </div>
            </div>
        </section>

        <section className='container-fluid d-flex flex-column align-items-end market-possibilities-container' style={{minHeight: "100vh"}}>
            <div className='col-11'>
                <h1 className='text-black title-text font-anton'>Market possibilities</h1>
            </div>

            <div className='col-11 position-relative'>
                <div className='col-lg-7 col-xl-8'>
                    <p className='text-black font-inter-light responsive-text-2'>We operate in the growing home services market, which is showing significant growth in the context of globalization and accelerated digitalization. As the demand for quality and convenient services grows, Servin is well positioned to capture a significant share of this market.</p>
                </div>

                <div className='market-data-svg-container z-2 mt-5'>
                    <div className='blurred-svg'></div>
                    <MarketPossibilitiesObject />
                </div>
            </div>
        </section>

        <section className='container col-10 d-flex flex-column align-items-center py-5'>
            <h1 className='title-text font-anton' style={{color: "#B0B0B0"}}>Our Vision</h1>

            <div className='d-flex flex-column row-gap-4 mt-5'>
                <p className='responsive-text-1-2 font-inter-light' style={{color: "#D9D9D9"}}>At Servin, we plan to create a seamless, reliable ecosystem that connects customers with skilled professionals, making services accessible and efficient for all. We aim to become a one-stop solution to everyday challenges, empowering both service providers and customers through convenience, transparency and innovation.</p>
                <p className='responsive-text-1-2 font-inter-light' style={{color: "#D9D9D9"}}>We believe in enabling people with skills to thrive by giving them flexible hours and control over pricing, and offering customers the best services at competitive prices. Servin is committed to strengthening the economy by bridging the gap between the quality of work and the needs of consumers.</p>
                <p className='responsive-text-1-2 font-inter-light' style={{color: "#D9D9D9"}}>Our aim is to simplify the process of finding, hiring and managing home services. Using smart technology and digital contracts, we ensure that our platform is easy to use, transparent and secure, providing an optimal experience for all users.</p>
                <p className='responsive-text-1-2 font-inter-light' style={{color: "#D9D9D9"}}>We are committed to the responsible development of Servin, focusing on long-term growth while maintaining high standards of quality and trust. Our vision includes scaling our platform in new markets, continuously improving user experience and developing relationships with partners and investors who share our values.</p>
            </div>
        </section>
    </div>
  )
}

export default InvestorRelationsPage