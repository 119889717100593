import React, {useEffect, useState} from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { ReactComponent as Menu } from '../assets/menu.svg';
import { ReactComponent as MenuWhite } from '../assets/menuWhite.svg';

import MenuLinks from '../components/MenuLinks';

export function HeaderHomePage() {
  gsap.registerPlugin(ScrollTrigger);

  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    gsap.set("#header-container", {backgroundColor: "rgba(255, 255, 255, 0)"});
    gsap.to("#header-container", {
      backgroundColor: "rgba(255, 255, 255, 1)",
      scrollTrigger: {
        trigger: "#header-container",
        start: "top top",
        end: "bottom top",
        scrub: true
      }
    });

    gsap.set("#header-logo", {color: "rgba(255, 255, 255, 1)"})
    gsap.to("#header-logo", {
      color: "rgba(60, 60, 60, 1)",
      scrollTrigger: {
        trigger: "#header-container",
        start: "top top",
        end: "bottom top",
        scrub: true
      }
    });

    gsap.to("#open-menu-vector-white", {
      stroke: "rgba(60, 60, 60, 1)",
      scrollTrigger: {
        trigger: "#header-container",
        start: "top top",
        end: "bottom top",
        scrub: true
      }
    });
  }, []);

  return (
    <div className='col-12 d-flex justify-content-center position-fixed top-0 z-3' id='header-container'>
      <div className='container p-2 d-flex flex-row justify-content-between align-items-center position-relative'>
          <a href='/' className='fs-1 fw-bold font-shanti' id='header-logo'>SERVIN</a>
          <a className='z-3 cursor-pointer' onClick={(e) => {setMenuOpen(true); document.body.style.overflow = "hidden";}}><MenuWhite /></a>
      </div>

      {menuOpen ? <MenuLinks onClose={setMenuOpen} /> : null }
    </div>
  )
}

export function HeaderWhiteTransparent() {
  gsap.registerPlugin(ScrollTrigger);

  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    gsap.set("#header-container", {backgroundColor: "rgba(180, 180, 180, 0)"});
    gsap.to("#header-container", {
      backgroundColor: "rgba(60, 60, 60, 0.7)",
      scrollTrigger: {
        trigger: "#header-container",
        start: "top top",
        end: "bottom top",
        scrub: true
      }
    });
  }, []);

  return (
    <div className='col-12 d-flex justify-content-center position-fixed top-0 z-3' id='header-container' style={{backdropFilter: "blur(6px)"}}>
      <div className='container p-2 d-flex flex-row justify-content-between align-items-center position-relative'>
          <a href='/' className='fs-1 fw-bold font-shanti white-header-text' style={{color: "#ffffff"}}>SERVIN</a>
          <a className='z-3 cursor-pointer white-header-menu' onClick={(e) => {setMenuOpen(true); document.body.style.overflow = "hidden";}}><MenuWhite /></a>
      </div>

      {menuOpen ? <MenuLinks onClose={setMenuOpen} /> : null }
    </div>
  )
}

const Header = () => {
  gsap.registerPlugin(ScrollTrigger);

  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    gsap.set("#header-container", {backgroundColor: "rgba(255, 255, 255, 0)"});
    gsap.to("#header-container", {
      backgroundColor: "rgba(255, 255, 255, 1)",
      scrollTrigger: {
        trigger: "#header-container",
        start: "top top",
        end: "bottom top",
        scrub: true
      }
    });
  }, []);

  return (
    <div className='col-12 d-flex justify-content-center position-fixed top-0 z-3' id='header-container'>
      <div className='container p-2 d-flex flex-row justify-content-between align-items-center position-relative'>
          <a href='/' className='text-dark fs-1 fw-bold font-shanti'>SERVIN</a>
          <a className='z-3 cursor-pointer' onClick={(e) => {setMenuOpen(true); document.body.style.overflow = "hidden";}}><Menu /></a>
      </div>

      {menuOpen ? <MenuLinks onClose={setMenuOpen} /> : null }
    </div>
  )
}

export default Header